import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma d’orella de 5 a 10 cm de diàmetre, sèssil, amb la cara superior tomentosa, grisa o color terra, a vegades zonada i vellutada. La cara inferior és gris violàcea o terrosa violàcia. Presenta costelles venoses formant una mena de xarxa. Les espores són blanques en massa, cilíndrico-allargades, granuloses de 14-20 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      